import { Controller } from 'stimulus'
import I18n from 'i18n-js'
import { applyMaskPattern } from '../../utilities/shared_methods'

export default class extends Controller {
  static targets = ['countryCode', 'input', 'submitButton']

  connect() {
    this.maskAndLabelRutField();
  }
  checkInputs(){
    const allFilled = this.inputTargets.every(input => input.value.trim().length > 3);

    if (allFilled) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'true');
    }
  }

  getCountry() {
    return this.countryCodeTarget.value;
  }

  setLocale() {
    let country = this.getCountry();
    if (country == 'US') {
      I18n.locale = 'en';
    } else {
      I18n.locale = `es-${country}`;
    }
  }

  getRutTarget(){
    return this.inputTargets.find(input => input.id === "rut");
  }

  maskAndLabelRutField() {
    this.setLocale();

    const mask = I18n.t('views.rut_field.mask');
    if (!mask || mask == 'unmask') return;

    let input = this.getRutTarget();
    let value = input.value;
    let formattedValue = applyMaskPattern(value, mask);
    input.value = formattedValue;
    input.placeholder = I18n.t('views.rut_field.placeholder');
  }
}
